import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Privacy from './privacy';
import TermsAndConditions from "./terms-and-conditions";
import DeleteAccountPage from "./delete-user-data";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/delete-user-data" element={<DeleteAccountPage />} />
                <Route path="/" element={<App />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
