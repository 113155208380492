import React from 'react';

const DeleteAccountPage = () => {
    const handleDeleteAccount = () => {
        window.location.href = 'mailto:anu@eternalembrace.app?subject=Delete%20My%20Account%20Data';
    };

    return (
        <div style={{ textAlign: 'center', padding: '50px', backgroundColor: '#B94053', height: '100%', color: 'white' }}>
            <h1>Delete Your Eternal Embrace Account</h1>
            <p>
                Are you sure you want to delete your account? Please send a mail requesting same by clicking button below.
            </p>
            <button onClick={handleDeleteAccount}>Delete My Account And Data</button>
        </div>
    );
};

export default DeleteAccountPage;
