import logo from './logo.svg';
import './App.css';
import {Link} from "react-router-dom";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <div className="button-group">
                    <Link to="/privacy" className="top-button">Privacy</Link>
                    <Link to="/terms-and-conditions" className="top-button">Terms and Conditions</Link>
                    <Link to="/delete-user-data" className="top-button">Delete user data</Link>
                </div>
                <img src={logo} className="App-logo" alt="logo" />
                <p>
                    Eternal Embrace
                </p>
            </header>
        </div>
    );
}

export default App;
